


















































































































import { BaseUser, Program, ProgramPlayer } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";
import { ProgramPaymentViewItem } from "@/types/Payment";
import { Header } from "@/types/Table";
import { Transaction } from "@sportango/backend";
import { LoadingMixin } from "@/mixins/Helpers";
import { WatchLoading } from "@/decorators/Loading";
import {
  collection,
  onSnapshot,
  query,
  QuerySnapshot,
  where
} from "@firebase/firestore";
import { DB } from "@/firebase";

import { Unsubscribe } from "@firebase/util";
import TablePaymentMethod from "@/components/Payments/TablePaymentMethod.vue";
import {
  TransactionCustomer,
  TRANSACTIONS_TABLE_NAME
} from "@sportango/backend";
import {
  getCorrectStatusAndMessage,
  getRightConvenienceFee,
  getRightPaymentMethod,
  parsePaymentDate
} from "@/utils/payments";
import RefreshIcon from "@/assets/icons/saxcons/refresh-circle-linear.svg";
import PaymentStatus from "@/components/Payments/PaymentStatus.vue";
import {
  SharedPaymentIntents,
  SharedPaymentIntentsWithPaymentMethod,
  SharedPaymentMethods
} from "@/mixins/Payments";
import EditableAmount from "@/components/Payments/EditableAmount.vue";
import CashPaymentButton from "@/components/Payments/CashPaymentButton.vue";
import dayjs from "dayjs";
import { ResponsiveMixin } from "@/mixins/Responsive";
import ProgramMobilePaymentItem from "@/components/Payments/ProgramMobilePaymentItem.vue";

@Component({
  name: "run-program-payment",
  components: {
    ProgramMobilePaymentItem,
    EditableAmount,
    CashPaymentButton,
    TablePaymentMethod,
    PaymentStatus,
    RefreshIcon
  }
})
export default class RunProgramPayment extends mixins(
  LoadingMixin,
  SharedPaymentIntents,
  SharedPaymentMethods,
  ResponsiveMixin,
  SharedPaymentIntentsWithPaymentMethod
) {
  isLoading = true;
  programPlayersLoaded = false;
  selectedPlayers: Array<ProgramPaymentViewItem> = [];
  amountEditors: Record<string, number> = {};
  private subscription: Unsubscribe | undefined;
  get selectedProgram(): Program | undefined {
    return this.$store.getters.programs.find(
      (p) => p.id === this.$route.params.id
    );
  }

  get programTransaction(): Transaction | undefined {
    return this.$store.getters.transactions.find(
      (t) => t.parentItem === this.selectedProgram?.id
    );
  }

  get headers(): Array<Header<ProgramPaymentViewItem>> {
    return [
      {
        value: "customerName",
        text: "Player"
      },
      {
        value: "amount",
        text: "Amount",
        align: "center",
        sortable: true,
        width: "150px"
      },
      {
        text: "Discount(%)",
        value: "discount",
        sortable: false,
        align: "center"
      },
      {
        value: "convenienceFee",
        text: "Fee",
        sortable: false,
        align: "center"
      },
      {
        value: "total",
        text: "Total",
        sortable: false,
        align: "center"
      },
      {
        value: "status",
        text: "Status",
        align: "center",
        sortable: false
      },
      {
        value: "paymentMethod",
        text: "Payment Method",
        sortable: false,
        align: "center",
        width: "150px"
      },
      {
        value: "paidInCash",
        text: "Cash",
        align: "center",
        sortable: false,
        width: "200px"
      },
      {
        value: "paymentDateShort",
        text: "Date",
        align: "center",
        sortable: false
      }
    ];
  }

  get programPlayerUsers(): Array<
    (BaseUser & { programInfo: ProgramPlayer }) | undefined
  > {
    const players = (this.selectedProgram?.programPlayers || []).map((p) => {
      const playerInfo = this.$store.getters.users.find((u) => u.uid === p.uid);
      if (playerInfo) {
        return {
          ...playerInfo,
          programInfo: p
        };
      }
    });
    if (
      players.filter((p) => p !== undefined).length ===
        this.selectedProgram?.programPlayers?.length &&
      this.$store.getters.merchantInfo?.merchantId
    ) {
      this.programPlayersLoaded = true;
    }
    return players;
  }

  get items(): Array<ProgramPaymentViewItem | undefined> {
    if (this.programPlayerUsers) {
      return this.programPlayerUsers.map((p) => {
        if (p) {
          let discountApplied = 0;
          const transactionCustomer = this.programTransaction?.customers?.find(
            (c) => c.uid === p.uid
          );
          if (transactionCustomer?.discountApplied) {
            discountApplied = Number(transactionCustomer.discountApplied);
          } else if (p.additionalInfo?.discountApplied?.value) {
            discountApplied = Number(p.additionalInfo?.discountApplied?.value);
          }
          const paymentIntent = this.paymentIntents_.find(
            (pI) => pI.id === transactionCustomer?.paymentIntentId
          );
          const { status, statusMessage } = getCorrectStatusAndMessage(
            transactionCustomer,
            paymentIntent
          );
          // set discount to zero if status is done and no discount saved
          if (
            (transactionCustomer?.discountApplied === undefined ||
              transactionCustomer.discountApplied === null) &&
            (status === "success" ||
              status === "processing" ||
              status === "requiresAttention")
          ) {
            discountApplied = 0;
          }
          const { short, full } = parsePaymentDate(
            paymentIntent?.created,
            transactionCustomer
          );
          const paymentMethod =
            this.paymentMethods_.find(
              (pM) => pM.id === getRightPaymentMethod(paymentIntent || null)
            ) || null;
          const defaultPaymentMethod =
            this.defaultPaymentMethods.find(
              (d) => d.customer === p.stripeCustomerId
            ) || null;
          const tableItem: ProgramPaymentViewItem = {
            customerName: p.displayName || p.email || "",
            amount: this.calculateAmountForPlayer(p.uid, transactionCustomer),
            convenienceFee: 0,
            total: this.calculateAmountForPlayer(p.uid, transactionCustomer),
            paymentMethod: paymentMethod || defaultPaymentMethod || null,
            status,
            discount: discountApplied || 0,
            statusMessage,
            paymentDateShort: short,
            paymentDate: full,
            uid: p.uid,
            disabled: (() => {
              if (p.stripeCustomerId && defaultPaymentMethod) {
                if (
                  status === "failed" ||
                  status === "notStarted" ||
                  status === "cancelled"
                ) {
                  if (this.programTransaction?.isRunning) {
                    return true;
                  }
                  return false;
                }
              }
              return true;
            })(),
            stripeCustomerId: p.stripeCustomerId,
            isPaymentRunning: (() => {
              if (transactionCustomer) {
                if (transactionCustomer.paidInCash) {
                  return false;
                }
                if (paymentIntent === undefined) {
                  switch (transactionCustomer.status) {
                    case "success":
                    case "failed":
                    case "missingStripeCustomerId":
                    case "missingPaymentMethod":
                    case "cancelled":
                    case "requiresAttention":
                    case "notStarted":
                      return false;
                    case "processing":
                      return true;
                    default:
                      return false;
                  }
                }
              }
              return false;
            })(),
            date: paymentIntent?.created || 0,
            defaultPaymentMethod: defaultPaymentMethod,
            itemId: p.uid,
            paidInCash: transactionCustomer?.paidInCash,
            playerStartDate: p.programInfo.startDate
              ? dayjs(p.programInfo.startDate).format("MM/DD/YYYY")
              : "--/--/----"
          };
          if (typeof this.amountEditors[tableItem.itemId] === "number") {
            tableItem.amount = Number(this.amountEditors[tableItem.itemId]);
          }
          tableItem.total = this.calculateTotal(
            p.uid,
            tableItem.amount,
            discountApplied
          );
          tableItem.convenienceFee = getRightConvenienceFee(
            tableItem.status,
            tableItem.total,
            tableItem.defaultPaymentMethod,
            transactionCustomer,
            this.$store.getters.merchantInfo
          );
          tableItem.total = tableItem.total + tableItem.convenienceFee;
          if (typeof tableItem.total === "number") {
            tableItem.total = Number(tableItem.total.toFixed(2));
          }
          return tableItem;
        }
      });
    }
    return [];
  }

  get showMarkAsPaidButton(): boolean {
    const enabledItems = this.items.filter((i) => !i?.disabled);
    const paymentNotRunning = enabledItems.filter((i) => !i?.isPaymentRunning);
    const notPaidInCash = paymentNotRunning.filter((i) => !i?.paidInCash);
    return notPaidInCash.length > 0;
  }

  get showMarkAsUnPaidButton(): boolean {
    const enabledItems = this.items.filter((i) => !i?.disabled);
    const paymentNotRunning = enabledItems.filter((i) => !i?.isPaymentRunning);
    const paidInCash = paymentNotRunning.filter((i) => i?.paidInCash);
    return paidInCash.length > 0;
  }

  get showRefreshButton(): boolean {
    return (
      this.items.filter(
        (i) => i?.status === "requiresAttention" || i?.status === "processing"
      ).length > 0
    );
  }

  get showRunButton(): boolean {
    return (
      this.items.filter((i) => !i?.disabled && !i?.isPaymentRunning).length > 0
    );
  }

  changeAmount(itemId: string, amount: number) {
    if (this.amountEditors[itemId]) {
      delete this.amountEditors[itemId];
    }
    this.amountEditors = {
      ...this.amountEditors,
      [itemId]: amount
    };
    this.amountEditors[itemId] = amount;
  }

  @WatchLoading()
  async mounted() {
    if (!this.selectedProgram) {
      await this.$store.dispatch("getProgramById", this.$route.params.id);
    }
    const q = query(
      collection(DB, TRANSACTIONS_TABLE_NAME),
      where("parentItem", "==", this.selectedProgram?.id)
    );
    this.subscription = onSnapshot(q, this.watchTransaction);
  }

  @WatchLoading()
  async watchTransaction({ docs }: QuerySnapshot) {
    const newTransactions = docs.map((d) => {
      return {
        ...d.data(),
        id: d.id
      };
    });

    this.$store.commit("transactions", newTransactions);

    // old implementation
    /*if (this.programTransaction) {
      await this.getPaymentIntents([this.programTransaction]);
      await this.getPaymentMethodsForIntents(this.paymentIntents);
    }*/

    // new Implementation
    this.paymentIntentsLoaded_ = false;
    this.paymentIntentIdsLength = 0;
    this.paymentIntents_ = [];
    this.paymentMethods_ = [];
    if (this.programTransaction) {
      this.getPaymentIntentsWithPaymentMethods([this.programTransaction]);
    }

    await this.getDefaultPaymentMethods(
      this.programPlayerUsers.map((p) => p?.stripeCustomerId || ""),
      this.$store.getters.merchantInfo?.merchantId || ""
    );
  }

  beforeDestroy() {
    if (this.subscription) {
      this.subscription();
    }
  }

  calculateAmountForPlayer(
    player: string,
    transactionCustomer?: TransactionCustomer
  ): number {
    if (typeof transactionCustomer?.amount === "number") {
      return transactionCustomer.amount;
    } else if (typeof transactionCustomer?.amount === "string") {
      return Number(transactionCustomer?.amount);
    }
    const playerInfo = this.selectedProgram?.programPlayers?.find(
      (p) => p.uid === player
    );
    if (playerInfo) {
      return Number(playerInfo.price) || 0;
    }
    return 0;
  }
  calculateTotal(player: string, total: number, discount?: number): number {
    let totalAmount = total;
    if (discount && totalAmount) {
      const discountValue = this.findDiscount(totalAmount, discount);
      const totalDiscountGiven = Number(
        (totalAmount - discountValue).toFixed(2)
      );
      return totalDiscountGiven;
    }
    return totalAmount || 0;
  }
  findDiscount(totalAmount: number, discountPercentage: number): number {
    return (totalAmount / 100) * discountPercentage;
  }
  get allowedSelectedPlayers(): ProgramPaymentViewItem[] {
    return this.selectedPlayers.filter((s) => !s.disabled);
  }

  async runIndividualPayment(item: ProgramPaymentViewItem) {
    this.isLoading = true;
    this.selectedPlayers = [item];
    await this.runPayment();
  }

  async runPayment() {
    this.isLoading = true;
    let customers: Array<TransactionCustomer> = [
      ...(this.programTransaction?.customers || [])
    ];
    let items = [...this.items];
    if (this.selectedPlayers.length > 0) {
      items = [...this.selectedPlayers];
    }
    if (this.programTransaction) {
      items
        .filter(
          (i) =>
            i &&
            !i.disabled &&
            i.stripeCustomerId !== undefined &&
            !i.isPaymentRunning
        )
        .forEach((i) => {
          if (i) {
            const indexOfSelected = customers.findIndex((c) => c.uid === i.uid);
            if (indexOfSelected >= 0) {
              customers[indexOfSelected] = {
                ...customers[indexOfSelected],
                retry: true,
                convenienceFee: i.convenienceFee,
                amount: i.amount,
                discountApplied: i.discount,
                description: `${this.selectedProgram?.name}, from ${dayjs(
                  i.playerStartDate,
                  "MM/DD/YYYY"
                ).format("MM/DD")} to ${dayjs(
                  this.selectedProgram?.endDate
                ).format("MM/DD")} for ${i?.customerName}`
              };
            } else {
              customers.push({
                uid: i.uid,
                id: i.stripeCustomerId,
                amount: i.amount,
                convenienceFee: i.convenienceFee,
                discountApplied: i.discount,
                description: `${this.selectedProgram?.name}, from ${dayjs(
                  i.playerStartDate,
                  "MM/DD/YYYY"
                ).format("MM/DD")} to ${dayjs(
                  this.selectedProgram?.endDate
                ).format("MM/DD")} for ${i?.customerName}`
              });
            }
          }
        });

      await this.$store.dispatch("updateTransaction", {
        id: this.programTransaction.id,
        customers,
        isRunning: true
      });
    } else {
      const payload: Transaction = {
        description: this.selectedProgram?.name,
        merchantId: this.$store.getters.merchantInfo?.merchantId,
        parentItem: this.selectedProgram?.id,
        customers: items
          .filter(
            (c) =>
              c &&
              !c.disabled &&
              c.stripeCustomerId !== undefined &&
              !c.isPaymentRunning
          )
          .map((i) => {
            return {
              uid: i?.uid,
              amount: i?.amount,
              id: this.programPlayerUsers.find((u) => u?.uid === i?.uid)
                ?.stripeCustomerId,
              convenienceFee: i?.convenienceFee,
              discountApplied: i?.discount,
              description: `${this.selectedProgram?.name}, from ${dayjs(
                i?.playerStartDate,
                "MM/DD/YYYY"
              ).format("MM/DD")} to ${dayjs(
                this.selectedProgram?.endDate
              ).format("MM/DD")} for ${i?.customerName}`
            };
          }),
        isRunning: true,
        transactionType: "program"
      };
      await this.$store.dispatch("createTransaction", payload);
    }
    this.selectedPlayers = [];
  }

  async changeCashStatus(uid: string, item: ProgramPaymentViewItem) {
    this.isLoading = true;
    let items = [...this.items];
    if (this.programTransaction) {
      // check if item is already in transaction or not
      let customerNotFound = true;
      let customers = this.programTransaction.customers?.map((c) => {
        if (c.uid === uid) {
          customerNotFound = false;
          c.paidInCash = !c.paidInCash;
          const item = items.find((i) => i?.uid === uid);
          if (item) {
            c.amount = item.amount;
            c.convenienceFee = item.convenienceFee;
            c.discountApplied = item.discount;
          }
        }
        return c;
      });
      if (customerNotFound) {
        customers?.push({
          uid,
          amount: item.amount,
          id: item.stripeCustomerId,
          convenienceFee: item.convenienceFee,
          paidInCash: !item.paidInCash,
          discountApplied: item.discount
        });
      }
      customers = customers?.map((c) => {
        if (c.uid === uid && c.paidInCash) {
          c.cashPaidDate = Number(new Date());
        }
        return c;
      });
      await this.$store.dispatch("updateTransaction", {
        id: this.programTransaction.id,
        customers
      });
    } else {
      const payload: Transaction = {
        description: this.selectedProgram?.name,
        merchantId: this.$store.getters.merchantInfo?.merchantId,
        parentItem: this.selectedProgram?.id,
        customers: items
          .filter((c) => c?.uid === uid)
          .map((i) => {
            return {
              uid: i?.uid,
              amount: i?.amount,
              id: this.programPlayerUsers.find((u) => u?.uid === i?.uid)
                ?.stripeCustomerId,
              convenienceFee: i?.convenienceFee,
              paidInCash: !i?.paidInCash,
              cashPaidDate: Number(new Date()),
              discountApplied: i?.discount,
              description: `${this.selectedProgram?.name}, from ${dayjs(
                i?.playerStartDate,
                "MM/DD/YYYY"
              ).format("MM/DD")} to ${dayjs(
                this.selectedProgram?.endDate
              ).format("MM/DD")} for ${i?.customerName}`
            };
          }),
        transactionType: "program"
      };
      await this.$store.dispatch("createTransaction", payload);
    }
    this.selectedPlayers = [];
  }
}
